import { Entity } from 'siren-parser';

export interface SubscriptionEntity extends Entity {
  properties: {
    threshold: number,
    type: string,
    entityType: string,
    deliveryModes: { [index:string] : boolean },
  },
}

export interface SubscriptionTypeDetails {
  type: string,
  title: string,
  description: string,
  threshold?: { units: string, default: number },
  hasInfoDialog?: boolean,
}

export const subscriptionTypeDetails = {
  orderApprovalPending: { type: 'orderApprovalPending', title: 'New Orders', description: 'Notify me when all scheduled orders are created' },
  lateOrder: { type: 'lateOrder', title: 'Late Orders', description: 'Notify me if an order has not arrived by midnight the day it was scheduled to arrive' },
  orderRequestReady: { type: 'orderRequestReady', title: 'Producer Requests', description: 'Notify me of all producer requests' },

  feedOutage: {
    type: 'feedOutage',
    hasInfoDialog: true,
    title: 'Empty Bin Out-of-Feed Events',
    threshold: { units: 'hours', default: 8 },
    description: 'Notify me when a empty bin out-of-feed event has been ongoing for',
  },
  slideManagementIssue: {
    type: 'slideManagementIssue',
    hasInfoDialog: true,
    title: 'Slide Management Out-of-Feed Events',
    threshold: { units: 'hours', default: 8 },
    description: 'Notify me when a slide management out-of-feed event has been ongoing for',
  },
  barnEmptyAtThreshold: { type: 'barnEmptyAtThreshold', title: 'Barn Empty In', threshold: { units: 'days', default: 2 }, description: 'Notify me when barn is forecasted to be empty in less than' },
  barnFeedFlatline: { type: 'barnFeedFlatline', title: 'Barn Consumption Flatline', threshold: { units: 'hours', default: 8 }, description: 'Notify me when a barn has not had consumption for' },
  multipleSlidesOpen: { type: 'multipleSlidesOpen', title: 'Multiple Slides Open', threshold: { units: 'hours', default: 8 }, description: 'Notify me when a barn has had multiple slides open for ' },
  consumptionDeviation: { type: 'consumptionDeviation', title: 'Consumption Deviation', threshold: { units: 'percent', default: 8 }, description: 'Notify me when consumption deviates from the past 3 day average by more than' },

  binConsumptionThreshold: { type: 'binConsumptionThreshold', title: 'Bin Consumption', threshold: { units: 'hours', default: 8 }, description: 'Notify me when bin consumption has not occurred for' },
  binPercentThreshold: { type: 'binPercentThreshold', title: 'Bin Percentage', threshold:{ units: 'percent', default: 5 }, description: 'Notify me when bin volume falls below' },
  binEmptyAtThreshold: { type: 'binEmptyAtThreshold', title: 'Bin Empty In', threshold:{ units: 'days', default: 1 }, description: 'Notify me when bin is forecasted to be empty in less than' },
  binUnreliableData: { type: 'binUnreliableData', title: 'Bin Unreliable Data', description: 'Notify me when a bin has been marked as having unreliable data' },
};

export const subscriptionGroups = {
  orders: {
    title: 'Order Notifications',
    types: new Map([
      subscriptionTypeDetails['orderApprovalPending'],
      subscriptionTypeDetails['lateOrder'],
      subscriptionTypeDetails['orderRequestReady'],
    ].map(type => [type.type, type])),
  },
  barns: {
    title: 'Barn Consumption Notifications',
    notificationsSnoozable: true,
    types: new Map([
      subscriptionTypeDetails['feedOutage'],
      subscriptionTypeDetails['slideManagementIssue'],
      subscriptionTypeDetails['barnEmptyAtThreshold'],
      subscriptionTypeDetails['barnFeedFlatline'],
      subscriptionTypeDetails['multipleSlidesOpen'],
      subscriptionTypeDetails['consumptionDeviation'],
    ].map(type => [type.type, type])),
  },
  bins: {
    title: 'Bin Consumption Notifications',
    notificationsSnoozable: true,
    types: new Map([
      subscriptionTypeDetails['binConsumptionThreshold'],
      subscriptionTypeDetails['binPercentThreshold'],
      subscriptionTypeDetails['binEmptyAtThreshold'],
    ].map(type => [type.type, type])),
  },
  data: {
    title: 'Data Notifications',
    types: new Map([
      subscriptionTypeDetails['binUnreliableData'],
    ].map(type => [type.type, type])),
  },
};
