const fontWeights = {
  regular: 400,
  semiBold: 600,
  bold: 700,
};

export function getMuiTypographyThemeOverrides(breakpoints) {
  const mediumUpBreakPoint = `@media (min-width:${breakpoints.md}px)`;
  const largeUpBreakPoint = `@media (min-width:${breakpoints.lg}px)`;
  return {
    fontFamily: ['Nunito Sans', 'sans-serif'].join(','),
    fontSize: 14,
    h1: {
      fontSize: '1.25rem',
      lineHeight: '1.5rem',
      fontWeight: fontWeights.bold,
      margin: '0.25em 0 0.25em 0',
      [mediumUpBreakPoint]: {
        fontSize: '1.375rem',
        lineHeight: '1.625rem',
      },
      [largeUpBreakPoint]: {
        fontSize: '1.5rem',
        lineHeight: '1.75rem',
      },
    },
    h2: {
      fontSize: '1rem',
      lineHeight: '1.25rem',
      fontWeight: fontWeights.regular,
      margin: '0.25em 0 0.25em 0',
      [mediumUpBreakPoint]: {
        fontSize: '1.125rem',
        lineHeight: '1.25rem',
      },
      [largeUpBreakPoint]: {
        fontSize: '1.25rem',
        lineHeight: '1.5rem',
      },
    },
    h3: {
      fontSize: '0.9375rem',
      lineHeight: '1.25rem',
      fontWeight: fontWeights.semiBold,
      margin: '0.25em 0 0.25em 0',
      [mediumUpBreakPoint]: {
        fontSize: '1rem',
        lineHeight: '1.25rem',
      },
      [largeUpBreakPoint]: {
        fontSize: '1.125rem',
        lineHeight: '1.375rem',
      },
    },
    h4: {
      fontSize: '0.75rem',
      lineHeight: '1.125rem',
      margin: '0.25em 0 0.25em 0',
      fontWeight: fontWeights.semiBold,
      textTransform: 'uppercase',
      [mediumUpBreakPoint]: {
        fontSize: '0.875rem',
        lineHeight: '1.125rem',
      },
      [largeUpBreakPoint]: {
        fontSize: '1rem',
        lineHeight: '1.25rem',
      },
    },
    body1: {
      fontSize: '0.875rem',
      lineHeight: '1.125rem',
      fontWeight: fontWeights.regular,
      [mediumUpBreakPoint]: {
        fontSize: '0.875rem',
        lineHeight: '1.125rem',
      },
      [largeUpBreakPoint]: {
        fontSize: '1rem',
        lineHeight: '1.25rem',
      },
    },
    body2: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: fontWeights.regular,
      [mediumUpBreakPoint]: {
        fontSize: '0.75rem',
        lineHeight: '1rem',
      },
      [largeUpBreakPoint]: {
        fontSize: '0.875rem',
        lineHeight: '1.25rem',
      },
    },
    button: {
      lineHeight: 2,
      textTransform: 'none',
    },
  };
}
