import React from 'react';
import { Icons } from '~/lib/bn-material-ui';
import { useAccountFeatures, useStore, useSirenEntity, BinIcon, MillBinIcon, InsightsIcon } from '~/utility/BinSentry-ui-utility';
import { useCurrentMillBinsGroup } from '~/bins/ingredient-bin/use-current-mill-bins-group.js';
import { subscriptionTypeDetails } from '~/notifications/subscription-entity-type.ts';

const ACCOUNT_ORG_TYPES = [
  'account',
  'account-group',
  'department',
  'customer',
  'farm',
  'barn',
];

export default ({ apiRoot, organizationEntity, userEntity }) => {
  const links = [];
  const { isProducer, userFeatures } = useStore();
  const { features, loading: accountFeaturesLoading } = useAccountFeatures({ organizationId: organizationEntity?.getLink('self')?.href });
  const { entity: benchmarksEntity, loading: benchmarksLoading } = useSirenEntity({ href: organizationEntity?.getLink('https://api.binsentry.com/rel/benchmarks')?.href });
  const { millBinsGroup, loading: millBinsGroupLoading } = useCurrentMillBinsGroup();
  const { entity: indexEntity } = useSirenEntity({ href: apiRoot });
  const orgTypeEntity = organizationEntity?.getSubEntity('https://api.binsentry.com/rel/organization-type');
  const orgType = orgTypeEntity?.properties.name;
  const hasBins = organizationEntity?.getSubEntity('https://api.binsentry.com/rel/bins');
  const isAccount = ACCOUNT_ORG_TYPES.includes(orgType);
  const showOrdersFeatures = isAccount || isProducer && hasBins;

  const { submenuLinks, submenuLinksLoading } = useSubmenuLinks({ organizationEntity, showOrdersFeatures });

  const loading = accountFeaturesLoading || submenuLinksLoading || benchmarksLoading || millBinsGroupLoading;
  if (!organizationEntity || !features || loading) {
    return { links };
  }

  if (!orgTypeEntity) {
    return { links };
  }

  const legacyBinsFeature = userFeatures?.legacyBinList || (isProducer && features.legacyBinList);

  const metricsUsedByInsights = ['outage-count', 'outage-duration', 'soft-outage-count', 'soft-outage-duration'];
  const hasMetrics = benchmarksEntity?.getSubEntities('https://api.binsentry.com/rel/metric').some(metric => metricsUsedByInsights.includes(metric.properties.metric));
  const showInsights = isAccount && !isProducer && hasMetrics;

  if (showOrdersFeatures) {
    links.push({
      title: 'Bins',
      icon: <BinIcon/>,
      href: '/customers',
      bottomBar: true,
    });

    const canViewOrders = organizationEntity.hasLink('https://api.binsentry.com/rel/search-orders');
    canViewOrders && links.push({
      title: 'Order Manager',
      icon: <Icons.Receipt/>,
      href: '/orders',
      bottomBar: true,
    });

    const canViewReports = organizationEntity.hasLink('https://api.binsentry.com/rel/search-flock-reports');
    canViewReports && links.push({
      title: 'Reports',
      icon: <Icons.Folder/>,
      href: '/reports',
      bottomBar: true,
    });
  }

  if (millBinsGroup && !isProducer) {
    links.push({
      title: 'Facility Inventory',
      icon: <MillBinIcon/>,
      href: '/inventory',
    });
  }

  if (showInsights) {
    links.push({
      title: 'Insights',
      icon: <InsightsIcon/>,
      href: '/insights',
    });
  }

  const showIssues = organizationEntity.hasLink('https://api.binsentry.com/rel/issues');
  if (showIssues) {
    links.push({
      title: 'Events',
      icon: <Icons.WarningRounded/>,
      href: '/events',
    });
  }

  const firstSettingsSubMenuWithLinks = submenuLinks['Settings'].find(submenu => submenu.links.length > 0);
  if (!isProducer && firstSettingsSubMenuWithLinks) {
    links.push({
      title: 'Settings',
      icon: <Icons.Settings/>,
      href: firstSettingsSubMenuWithLinks.links[0].href,
      hideInDrawer: true,
    });
  }

  if (legacyBinsFeature) {
    links.push('-');

    links.push({
      title: 'Bins (legacy)',
      icon: <BinIcon/>,
      href: '/bins',
      bottomBar: true,
    });
  }

  if (orgType === 'sales-account') {
    links.push({
      title: 'Customers',
      href: '/related',
      icon: <Icons.PeopleAlt/>,
    });
  }

  const showDevices = organizationEntity.hasSubEntityByClass('devices') && ['dealer', 'service-account'].includes(orgType);

  if (showDevices) {
    links.push({ title: 'Devices', icon: <Icons.Router/>, href: '/devices', bottomBar: true });
  }

  if (orgType === 'service-account') {
    links.push({ title: 'Review', icon: <Icons.RateReview/>, href: '/review' });
  }

  const workOrderLocationsLink = organizationEntity.getSubEntityByRel('https://api.binsentry.com/rel/work-order-locations');

  if (workOrderLocationsLink) {
    if (['service-account', 'service-partner'].includes(orgType)) {
      links.push({
        title: 'Work Orders',
        href: '/work-order-locations',
        icon: <Icons.Work/>,
        bottomBar: true,
      }, {
        title: 'Scheduler',
        href: '/scheduler',
        icon: <Icons.ListAlt/>,
        bottomBar: true,
      });
    }
  }

  const userWorkOrderLocationsLinks = userEntity?.getSubEntityByRel('https://api.binsentry.com/rel/work-order-locations');

  if (userWorkOrderLocationsLinks) {
    links.push({
      title: 'My Schedule',
      bottomBar: true,
      href: '/work-order-schedule',
      icon: <Icons.Schedule/>,
    });
  }

  if (indexEntity?.getLink('https://api.binsentry.com/rel/billing')) {
    links.push({
      title: 'Billing',
      href: '/billing-management',
      icon: <Icons.AttachMoney/>,
    });
  }

  links.push({
    title: 'Help Center',
    href: 'https://knowledge.binsentry.com',
    icon: <Icons.Help/>,
    newWindow: true,
  });

  const selectedDirectLink = links.find(link => link.title && window.location.pathname.startsWith(link.href))?.title;

  const selected = selectedDirectLink || _selectedSubLink(submenuLinks);

  return { links, submenuLinks, selected };
};

function _selectedSubLink(subLinks) {
  return Object.keys(subLinks).find(group =>
    subLinks[group].find(({ links }) => links
      .find(link => window.location.pathname.startsWith(link?.href)),
    ),
  );
}

function useSubmenuLinks({ organizationEntity, showOrdersFeatures }) {
  const { properties: orgTypeProperties } = organizationEntity?.getSubEntity('https://api.binsentry.com/rel/organization-type') || {};
  const showBilling = organizationEntity?.hasEntity('https://api.binsentry.com/rel/contract');
  const showFeedTemplates = organizationEntity?.hasLink('https://api.binsentry.com/rel/consumption-plans') && orgTypeProperties.name !== 'binsentry';

  const { entity: importsEntity, loading: importsLoading } = useSirenEntity({ href: organizationEntity?.getSubEntity('https://api.binsentry.com/rel/imports')?.href });
  const showBinImports = importsEntity?.hasLink('https://api.binsentry.com/rel/bin-imports');
  const showUserImports = importsEntity?.hasLink('https://api.binsentry.com/rel/user-imports');
  const showImports = showBinImports || showUserImports;

  const firstNotificationType = Object.keys(subscriptionTypeDetails)[0];
  const { entity: subscriptionDefaultsEntity, loading: subscriptionDefaultsLoading } = useSirenEntity({ href: organizationEntity?.getLink('https://api.binsentry.com/rel/subscription-defaults')?.href });
  const showCorporateNotificationDefaults = subscriptionDefaultsEntity?.hasAction(`add-${firstNotificationType}`) || subscriptionDefaultsEntity?.getSubEntityByClass(firstNotificationType)?.getAction(`update-${firstNotificationType}`);

  return {
    submenuLinks: {
      ['Notifications']: [
        {
          title: 'Notifications',
          hideInDrawer: true,
          links: [
            { title: 'Inbox', href: '/notifications/inbox' },
            { title: 'Settings', href: '/notifications/settings' },
            { title: 'Customize Notifications', href: '/notifications/customize' },
          ],
        },
      ],
      ['Settings']: [
        {
          title: 'Settings',
          links: [
            ...showCorporateNotificationDefaults ? [{ title: 'Notification Recommendations', href: '/notifications/recommendations' }] : [],
            ...showOrdersFeatures ? [{ title: 'Feeds', href: '/feeds' }] : [],
            ...showFeedTemplates ? [{ title: 'Feed Budgets', href: '/feed-budgets/templates' }] : [],
            { title: 'Organizations', href: '/organizations' }],
        },
        ...showBilling ? [{
          title: 'Account',
          links: [{ title: 'Billing', href: '/billing' }],
        }] : [],
        ...showImports ? [{
          title: 'Imports',
          links: [
            ...showBinImports ? [{ title: 'Bin Imports', href: '/bin-imports' }] : [],
            ...showUserImports ? [{ title: 'User Imports', href: '/user-imports' }] : [],
          ],
        }] : [],
      ],
    },
    loading: importsLoading || subscriptionDefaultsLoading,
  };
}
