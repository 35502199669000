import classNames from 'classnames';
import React from 'react';
import {
  Box,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '~/lib/bn-material-ui';

import { useSirenEntity, useStore } from '~/utility/BinSentry-ui-utility';
import getNavigationLinks from './get-navigation-links';
import { baseStyles } from './drawer-styles';
import Submenu from './Submenu';
import MenuTitlePopper from './MenuTitlePopper';
import { NavigationDrawerMobile } from './navigation-drawer-mobile';

function ResponsiveDrawer({ apiRoot }) {
  const { currentOrgHref, currentUserHref, query, menuHidden } = useStore();
  const printMode = [true, 'true'].includes((query || {}).printMode);
  const focusMode = [true, 'true'].includes((query || {}).focusMode);
  const sideBarOpen = !printMode && !menuHidden && !focusMode;

  const { entity: organizationEntity } = useSirenEntity({
    href: currentOrgHref,
  });
  const { entity: userEntity } = useSirenEntity({ href: currentUserHref });

  const { links, submenuLinks, selected } = getNavigationLinks({
    apiRoot,
    organizationEntity,
    userEntity,
  });

  const enableSubmenu = !!(submenuLinks && submenuLinks[selected]);
  const classes = baseStyles({ enableSubmenu });

  const [popoverConfig, setPopoverConfig] = React.useState(null);

  const popperHandlers = {
    open: (event, title) => setPopoverConfig({ el: event.currentTarget, title }),
    close: () => setPopoverConfig({ ...popoverConfig, el: null }),
  };

  return (
    <div
      className={sideBarOpen ? classes.root : classes.rootHidden}
    >
      <CssBaseline />
      <nav className={classes.drawer}>
        <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
          {enableSubmenu && (<Submenu open={sideBarOpen} links={submenuLinks[selected]} />)}
          <Drawer
            variant='permanent'
            classes={{
              paper: classes.drawerInner,
            }}
            anchor='left'
            open={sideBarOpen}
          >
            <List className={classes.drawerLinksList}>
              {links.map((item, index) => <NavigationLink key={index} {... { item, classes, popperHandlers, selected }} /> )}
            </List>
          </Drawer>

          <MenuTitlePopper anchorEl={popoverConfig?.el} title={popoverConfig?.title}/>
        </Box>
      </nav>
      <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
        <NavigationDrawerMobile links={links} submenuLinks={submenuLinks} />
      </Box>
    </div>
  );
}

const NavigationLink = ({ item, classes, popperHandlers, selected, index }) => {
  if (item === '-') {
    return <Divider key={`divider-${index}`} className={classes.listItem} />;
  }

  const props = {
    href: item.href,
    key: item.title,
  };

  if (item.newWindow) {
    props.target = '_blank';
  }

  return (
    <ListItemButton
      component='a'
      {...props}
      className={classNames(
        classes.listItem,
        item.title === selected ? classes.listItemSelected : classes.listItemUnselected,
        item.subLink && classes.subLinkListItem,
      )}
      onMouseEnter={(event) => popperHandlers.open(event, item.title)}
      onMouseLeave={popperHandlers.close}
    >
      <ListItemIcon className={classes.listItemIcon}>{item.icon}</ListItemIcon>
      <ListItemText primary={item.title} className={classes.drawerItemText} />
    </ListItemButton>
  );
};

export default ResponsiveDrawer;
