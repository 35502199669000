import { muiTheme } from '~/utility/BinSentry-ui-utility';

export default {
  nav: {
    zIndex: 1201, // this is greater than MUI-Papers default of 1200, less than MuiDialog of 1300
    appBar: {
      color: '#fff',
      backgroundColor: muiTheme.palette.purple.dark,
      minHeight: muiTheme.spacing(7),
    },
    menu: {
      width: parseInt(muiTheme.spacing(7)) + 1,
      color: muiTheme.palette.purple.dark,
      backgroundColor: '#fff',
    },
    submenu: {
      width: 210,
      highlightColor: muiTheme.palette.purple.dark,
    },
  },
};
