import { makeStyles, Paper, Popper, Typography } from '~/lib/bn-material-ui';
import React from 'react';
import navTheme from './nav-styles';

const useStyles = makeStyles((theme) => {
  return {
    popper: {
      zIndex: navTheme.nav.zIndex + 1,
      boxShadow: theme.shadows[3],
    },
    arrow: {
      position: 'absolute',
      fontSize: 7,
      color: navTheme.nav.menu.color,
      left: 0,
      marginLeft: '-0.9em',
      marginTop: 8,
      height: '3em',
      width: '1em',
      '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${navTheme.nav.menu.color} transparent transparent`,
      },
    },
    popperPaper: {
      padding: '3px 8px 2px 8px',
      backgroundColor: navTheme.nav.menu.color,
      color: navTheme.nav.menu.backgroundColor,
    },
  };
});

function MenuTitlePopper({ anchorEl, title }) {
  const styles = useStyles();
  return (
    <Popper
      open={!!anchorEl}
      anchorEl={anchorEl}
      placement='right'
      className={styles.popper}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ]}
    >
      <span className={styles.arrow} />
      <Paper className={styles.popperPaper}>
        <Typography>{title}</Typography>
      </Paper>
    </Popper>);
}

export default MenuTitlePopper;
