import _ from 'lodash';
import { createTheme, createGenerateClassName } from '~/lib/bn-material-ui';
import { colors } from './colours.js';
import { getMuiTypographyThemeOverrides } from './mui-typography.js';

const palette = {
  ...colors,
  primary: {
    ...colors,
    main: colors.purple[50],
  },
  info: {
    light: colors.blue[60],
    main: colors.blue[70],
    dark: colors.blue[80],
  },
  error: {
    light: colors.red[30],
    main: colors.red[70],
    dark: colors.red.text,
  },
  warning: {
    light: colors.yellow[40],
    main: colors.yellow[50],
    dark: colors.yellow.text,
  },
  secondary: {
    light: colors.teal[40],
    main: colors.teal[60],
    dark: colors.teal[70],
  },
  background: {
    default: colors.gray[10],
  },
  fillLevel: {
    low: colors.red[50],
    lowDark: colors.red[60],
    medium: colors.tangerine[40],
    mediumDark: colors.tangerine[50],
    high: colors.green[40],
    highDark: colors.green[50],
  },
};

const breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

export const theme = createTheme({
  palette: _.omit(palette, 'grey'),
  breakpoints: {
    values: breakpoints,
  },
  typography: getMuiTypographyThemeOverrides(breakpoints),
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: '0.875rem',
          lineHeight: 1.43,
          letterSpacing: '0.01071em',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.orientation === 'vertical' && ownerState.variant === 'middle' && {
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
          }),
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'white',
          boxShadow: `0.1rem 0.1rem 0.2rem 0 ${colors.gray[30]}`,
          border: `1px solid ${colors.gray[40]}`,
          color: 'black',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'unset',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: palette.secondary.main,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: palette.secondary.main,
          },
        },
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            color: palette.secondary.main,
          },
          '& .MuiInput-underline': {
            '&:before': {
              borderColor: palette.secondary.main,
            },
            '&:after': {
              borderColor: palette.secondary.main,
            },
            '&:hover:not(.Mui-disabled):before': {
              borderBottomColor: palette.secondary.main,
            },
          },
        },
      },
    },
    MuiInput: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        root: {
          '& .MuiSvgIcon-root': {
            fill: palette.secondary.main,
          },
          '& .MuiInput-underline': {
            '&:before': {
              borderBottomColor: palette.secondary.main,
            },
            '&:after': {
              borderBottomColor: palette.secondary.main,
            },
            '&:hover:not(.Mui-disabled):before': {
              borderBottomColor: palette.secondary.main,
            },
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        variant: 'standard',
      },
      styleOverrides: {
        standard: {
          transform: 'translate(0, 1.5px) scale(0.75)',
          transformOrigin: 'top left',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiSlider: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: '10rem',
        },
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        variant: 'standard',
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          color: palette.gray[80],
          '& .MuiSvgIcon-root': {
            color: palette.gray[60],
          },
          '& li': {
            gap: '0.5rem',
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 'bold',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        text: {
          textDecoration: 'underline',
          '&.MuiButton-root:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: palette.gray[80],
          backgroundColor: palette.gray[30],
        },
      },
    },
  },
});

let ruleCount = 0;
const muiGenerateClassName = createGenerateClassName();
export const generateClassName = (rule, styleSheet) => {
  // If this is a global static MUI style, use the mui lib fn
  const name = styleSheet.options.name;
  if (name && name.indexOf('Mui') === 0) {
    return muiGenerateClassName(rule, styleSheet);
  }

  return `${rule.key}-${ruleCount++}`;
};
