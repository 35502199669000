import React, { useEffect, useState, useRef } from 'react';
import {
  AppBar,
  Badge,
  Button,
  Toolbar,
  makeStyles,
  IconButton,
  Typography,
  Box,
  Icons,
} from '~/lib/bn-material-ui';
import { entityStore, useSirenEntity, useStoreValues, useAccountFeatures } from '~/utility/BinSentry-ui-utility';
import MillSelector from './MillSelector';
import ProfileMenu from './ProfileMenu';
import '~/legacy-lit/js/search/search-box';
import navTheme from './nav-styles';

const useStyles = makeStyles(() => {
  return {
    appBar: {
      zIndex: navTheme.nav.zIndex + 1,
      backgroundColor: navTheme.nav.appBar.backgroundColor,
    },
    appBarToolbar: {
      minHeight: navTheme.nav.appBar.minHeight,
    },
    spacer: {
      flex: 1,
    },
    navIcon: {
      // Adapted from IconButton.js source - it assumes default, primary or secondary color
      minWidth: '1em',
      color: navTheme.nav.appBar.color,
      '&:hover': {
        backgroundColor: 'rgba(255,255,255,0.04)',
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    hidden: {
      display: 'none',
    },
    selectorContainer: {
      marginRight: '0.5em',
    },
  };
});

const ACCOUNT_ORG_TYPES = [
  'account',
  'account-group',
  'department',
  'customer',
  'farm',
  'barn',
];

function BnAppBar({ apiRoot }) {
  const styles = useStyles();
  const { currentOrgHref, menuHidden, query = {} } = useStoreValues(['currentOrgHref', 'menuHidden', 'query']);

  const searchboxRef = useRef();

  const { features, loading } = useAccountFeatures({ organizationId: currentOrgHref });

  const printMode = [true, 'true'].includes(query.printMode);
  const focusMode = [true, 'true'].includes(query.focusMode);

  if (printMode || menuHidden) {
    return null;
  }

  const showColdWarning = features?.coldWeatherAlert && !loading && !focusMode;

  return (
    <AppBar classes={{ root: styles.appBar }}>
      <Toolbar classes={{ root: styles.appBarToolbar }}>
        <Button href={'/'}>
          <Box sx={{ display: { xs: 'flex', sm: 'none' } }}>
            <img src='/images/binsentry_shield_white.svg' alt='BinSentry Logo' height='35'/>
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <img src='/images/binsentry_logo_white.svg' alt='BinSentry Logo'/>
          </Box>
        </Button>

        {showColdWarning ? <ColdAlert /> : <div className={styles.spacer}/>}

        <div className={styles.selectorContainer}>
          <MillSelector href={currentOrgHref} apiRoot={apiRoot}/>
        </div>

        <div className={styles.hidden}>
          <search-box ref={searchboxRef} href={currentOrgHref}/>
        </div>

        {!focusMode && currentOrgHref && (
          <IconButton
            onClick={() => searchboxRef.current.open()}
            className={styles.navIcon}
            size='large'>
            <Icons.Search/>
          </IconButton>
        )}

        <Links apiRoot={apiRoot} currentOrgHref={currentOrgHref} focusMode={focusMode} />

        <ProfileMenu/>
      </Toolbar>
    </AppBar>
  );
}

const useAlertStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    flexGrow: 1,
    alignSelf: 'stretch',
    display: 'flex',
    justifyContent: 'stretch',
    padding: '6px',
    zIndex: 100,
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: 0,
      left: 0,
    },
  },
  alert: {
    cursor: 'pointer',
    backgroundColor: theme.palette.teal[20],
    border: `2px solid ${theme.palette.teal[40]}`,
    color: theme.palette.teal[80],
    padding: '.5rem',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'flex-start',
    gap: '1rem',
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      gap: 0,
    },
  },
  alertIcon: {
    color: theme.palette.teal[40],
  },
  title: {
    textDecoration: 'underline',
    lineHeight: '24px',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  more: {
    margin: '1rem 0',
  },
}));

function ColdAlert() {
  const styles = useAlertStyles();
  const [more, setMore] = useState(false);
  const toggleMore = () => setMore(!more);
  return (
    <div className={styles.container}>
      <div className={styles.alert} onClick={toggleMore} role='button' tabIndex={0}>
        <Icons.AcUnit className={styles.alertIcon} />
        <div className={styles.content}>
          <Typography variant='body1' className={styles.title}>
            Cold Weather Alert
          </Typography>
          {more && <div className={styles.more}>
            <Typography variant='body2'>
            Some devices in your account are in an area of extreme cold weather.<br/>
            This can result in some bins proactively hibernating to preserve battery life and internal components.
            </Typography>
          </div>}
        </div>
        {more ? <Icons.KeyboardArrowUp /> : <Icons.KeyboardArrowDown />}
      </div>
    </div>
  );
}

function Links({ apiRoot, currentOrgHref, focusMode }) {
  const styles = useStyles();
  const links = useAppBarLinks({ apiRoot, currentOrgHref, focusMode });

  return <>
    {links.map(link => (
      <IconButton key={link.title} href={link.href} className={styles.navIcon} size='large'>
        {link.badgeCount ? <Badge badgeContent={link.badgeCount} color='secondary' overlap='rectangular'>{link.icon}</Badge> : link.icon}
      </IconButton>
    ))}
  </>;
}

const useAppBarLinks = ({ apiRoot, currentOrgHref, focusMode }) => {
  const [lastPollTime, setLastPollTime] = useState();

  const { entity: rootEntity } = useSirenEntity({ href: apiRoot });
  const { entity: activeNotificationsEntity } = useSirenEntity({ href: rootEntity?.getLink('https://api.binsentry.com/rel/notifications-active')?.href });
  const { entity: organizationEntity } = useSirenEntity({ href: currentOrgHref });

  useEffect(() => {
    const now = new Date().valueOf();
    const handle = setTimeout(() => {
      activeNotificationsEntity && entityStore.invalidateHref(activeNotificationsEntity.getLink('self').href);
      setLastPollTime(now);
    }, 1000 * 60 * 5);
    return () => clearTimeout(handle);
  }, [lastPollTime, activeNotificationsEntity]);

  const signOutLink = {
    title: 'Sign Out',
    icon: <Icons.ExitToApp/>,
    href: '/logout',
  };

  if (focusMode) {
    return [signOutLink];
  }

  const orgTypeEntity = organizationEntity?.getSubEntityByRel('https://api.binsentry.com/rel/organization-type');
  const showNotifications = ['binsentry', 'billing-account', ...ACCOUNT_ORG_TYPES].includes(orgTypeEntity?.properties.name);
  const notificationsCount = activeNotificationsEntity?.getSubEntities('item').filter(entity => !entity.properties.read).length || 0;

  return [
    ...showNotifications ? [{
      title: 'Notifications',
      icon: <Icons.Notifications/>,
      badgeCount: notificationsCount,
      href: '/notifications/inbox',
    }] : [],
  ];
};

export default BnAppBar;
