import React from 'react';
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, makeStyles, Icons } from '~/lib/bn-material-ui';
import navTheme from './nav-styles';

const useStyles = makeStyles((theme) => {
  return {
    submenu: {
      position: 'fixed',
      paddingTop: navTheme.nav.appBar.minHeight,
      marginLeft: navTheme.nav.menu.width,
      width: navTheme.nav.submenu.width,
      boxShadow: theme.shadows[3],
    },
    submenuSectionTitle: {
      textTransform: 'uppercase',
      color: navTheme.nav.submenu.highlightColor,
    },
    submenuLink: {
      '& .MuiListItemIcon-root': {
        minWidth: '2em',
      },
      padding: '.15em 1.2em .15em .75em',
    },
    submenuLinkSelected: {
      backgroundColor: theme.palette.primary.gray[20],
      color: navTheme.nav.submenu.highlightColor,
      '& .MuiListItemIcon-root': {
        color: 'unset',
        '& .MuiSvgIcon-root': {
          transform: 'scale(0.6)',
        },
      },
      '& .MuiListItemText-primary': {
        fontWeight: 700,
      },
    },
    submenuLinkLast: {
      marginBottom: '2em',
    },
  };
});

function Submenu({ links, open }) {
  const styles = useStyles();
  if (!links?.length) {
    return null;
  }

  const renderedSubmenuLinks = links.map(item => _renderSection({ item, styles }));

  return (
    <Drawer
      key={`k-${links.length}`}
      classes={{
        paper: styles.submenu,
      }}
      variant='persistent'
      open={open}>
      <List>
        {renderedSubmenuLinks}
      </List>
    </Drawer>
  );
}

const _renderSection = ({ item, styles }) => {
  return [
    ...item.title ? [(
      <ListItem key={item.title} className={styles.submenuSectionTitle}>
        <ListItemText>{item.title}</ListItemText>
      </ListItem>
    )] : [],
    ...item.links.map((link, index) => {
      return _renderNavigationLink({ link, styles, last: item.links.length - 1 === index });
    }),
  ];
};

const _renderNavigationLink = ({ link, styles, last }) => {
  const selected = window.location.pathname.startsWith(link.href);
  return (
    <ListItemButton
      component='a'
      href={link.href}
      key={link.title}
      className={`${styles.submenuLink} ${
        selected ? styles.submenuLinkSelected : ''
      } ${
        last ? styles.submenuLinkLast : ''
      }`}
    >
      <ListItemIcon>
        {selected ? <Icons.ArrowForwardIosRounded /> : null}
      </ListItemIcon>
      <ListItemText primary={link.title} />
    </ListItemButton>
  );
};

export default Submenu;
