import React from 'react';
import { ThemeProvider, ThemeProviderV4, StyledEngineProvider, StylesProviderV4, Box } from '~/lib/bn-material-ui';
import { BnErrorBoundary } from '~/common/bn-error-boundary';
import ResponsiveDrawer from './drawer.component';
import { generateClassName, useStoreValues, muiTheme } from '~/utility/BinSentry-ui-utility';
import BnAppBar from './BnAppBar';

function Navigation({ ['api-root']: apiRoot, children }) {
  // We want to not request api-root until we have a token / are logged in.
  // This is to allow time for the token to be set in the store, allowing us to get a logged-in version of api-root.
  const { token } = useStoreValues(['token']);

  return <BnErrorBoundary locationTag='navigation'>
    <Box sx={{
      display: 'grid',
      gridTemplateColumns: 'auto 1fr',
      gridTemplateRows: '1fr',
      minHeight: '100%',
      overflow: 'hidden',
    }}>
      <StyledEngineProvider generateClassName={generateClassName} injectFirst>
        <StylesProviderV4 generateClassName={generateClassName}>
          <ThemeProvider theme={muiTheme}>
            <ThemeProviderV4 theme={muiTheme}>
              <BnAppBar apiRoot={token && apiRoot}/>
              <ResponsiveDrawer apiRoot={token && apiRoot} />
              {children}
            </ThemeProviderV4>
          </ThemeProvider>
        </StylesProviderV4>
      </StyledEngineProvider>
    </Box>
  </BnErrorBoundary>;
}

export default Navigation;
