import React, { useState } from 'react';
import {
  BottomNavigation,
  BottomNavigationAction,
  Backdrop,
  Drawer,
  List,
  ListItem,
  IconButton,
  ListItemText,
  makeStyles,
  ListItemButton,
  Icons,
} from '~/lib/bn-material-ui';
import navTheme from './nav-styles';
import { muiTheme } from '~/utility/BinSentry-ui-utility';

const SUB_HEADERS = [
  'Menu', // Will render in this order. Anything not defined in _subLinks will use Menu
  'Notifications',
  'Settings',
  'Account',
  'Imports',
];

const useStyles = makeStyles<typeof muiTheme>((theme) => ({
  drawerBase: {
    backgroundColor: navTheme.nav.menu.backgroundColor,
    color: navTheme.nav.menu.color,
    zIndex: navTheme.nav.zIndex + 2,
  },
  drawerLinksList: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingBottom: '5rem',
  },
  drawerSubHeader: {
    backgroundColor: theme.palette.purple[10],
    color: theme.palette.purple[50],
    textTransform: 'uppercase',
    '& .MuiTypography-body1': {
      fontWeight: 600,
    },
  },
  drawerItemText: {
    whiteSpace: 'nowrap',
    color: theme.palette.gray[70],
  },
  drawerItemSelected: {
    backgroundColor: theme.palette.purple[50],
    color: theme.palette.common.white,
    '& .MuiTypography-root': {
      color: theme.palette.common.white,
    },
    '&:hover': {
      backgroundColor: theme.palette.purple[50],
      color: theme.palette.common.white,
    },
  },
  listItemIcon: {
    color: 'currentColor',
  },
  backdrop: {
    zIndex: navTheme.nav.zIndex - 1,
  },
  bottomNavigation: {
    backgroundColor: navTheme.nav.menu.color,
    position: 'fixed',
    color: navTheme.nav.menu.backgroundColor,
    bottom: 0,
    width: '100%',
    zIndex: navTheme.nav.zIndex,
    '& .MuiBottomNavigationAction-root': {
      minWidth: 'auto',
      color: navTheme.nav.menu.backgroundColor,
      paddingTop: '8px',
      '& .MuiBottomNavigationAction-label': {
        whiteSpace: 'nowrap',
      },
    },
  },
  closeButtonContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100vw',
    display: 'flex',
    zIndex: navTheme.nav.zIndex + 3,
    justifyContent: 'center',
    background: 'linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%)',
  },
  closeButton: {
    border: `1px solid ${theme.palette.gray[30]}`,
    backgroundColor: navTheme.nav.menu.backgroundColor,
    margin: '1rem',
    width: '3rem',
    height: '3rem',
  },
}));

type NavigationSubMenuHeader = {
  title: string;
  links: NavigationLink[];
}

type NavigationLink = {
  title: string;
  href: string;
  bottomBar?: boolean;
  icon: React.ReactNode;
  subHeader?: string;
  hideInDrawer?: boolean;
}

export const NavigationDrawerMobile = ({
  links = [],
  submenuLinks = {},
}: {
  links: NavigationLink[];
  submenuLinks: Record<string, NavigationSubMenuHeader[]>;
}) => {
  const [bottomDrawerOpen, setBottomDrawerOpen] = useState(false);
  const styles = useStyles();

  const subMenuHeaders = Object.values(submenuLinks).flat() as NavigationSubMenuHeader[];
  const subMenuHeaderLinks = subMenuHeaders.flatMap(({ links, title }) => links.map((link) => ({ ...link, subHeader: title })));
  const allLinks = [...links, ...subMenuHeaderLinks].map(link => ({ ...link, subHeader: link.subHeader || 'Menu' }));
  const filteredLinks = allLinks.filter(link => !link.hideInDrawer && link.href && link.title);

  const bottomLinks = links.filter(item => item.bottomBar);
  const showLabels = bottomLinks.length < 4;

  return (<div>
    <BottomNavigation
      showLabels={showLabels}
      className={styles.bottomNavigation}
    >
      {bottomLinks.map((item) => {
        return (
          <BottomNavigationAction
            label={showLabels ? item.title : null}
            key={item.title}
            href={item.href}
            icon={item.icon}
            onClick={() => setBottomDrawerOpen(false)}
          />
        );
      })}

      <BottomNavigationAction
        label={showLabels ? 'Menu' : null}
        key='menu'
        icon={<Icons.Menu className={styles.listItemIcon} />}
        onClick={() => setBottomDrawerOpen(true)}
      />
    </BottomNavigation>
    <Drawer
      anchor='bottom'
      variant='persistent'
      open={bottomDrawerOpen}
      onClose={() => setBottomDrawerOpen(false)}
      classes={{
        paper: styles.drawerBase,
      }}
    >

      <List className={styles.drawerLinksList}>
        {SUB_HEADERS.map((subHeader) => {
          const subMenuLinks = filteredLinks.filter(link => link.subHeader === subHeader);
          return <SubMenuSection key={subHeader} title={subHeader} links={subMenuLinks} setBottomDrawerOpen={setBottomDrawerOpen}/>;
        })}
      </List>
    </Drawer>
    <Backdrop
      className={styles.backdrop}
      open={bottomDrawerOpen}
      onClick={() => setBottomDrawerOpen(false)}
    />
    {bottomDrawerOpen &&
      <div className={styles.closeButtonContainer}>
        <IconButton
          className={styles.closeButton}
          onClick={() => setBottomDrawerOpen(false)}
          size='large'><Icons.Close /></IconButton>
      </div>
    }
  </div>
  );
};

const SubMenuSection = ({
  title,
  links = [],
  setBottomDrawerOpen,
} : {
  title: string;
  links: NavigationLink[];
  setBottomDrawerOpen: (open: boolean) => void;
}) => {
  const styles = useStyles();
  if (!links.length) {
    return null;
  }
  return <div>
    <ListItem className={styles.drawerSubHeader}>
      <ListItemText primary={title} />
    </ListItem>
    {links.map(link => {
      const selected = window.location.pathname.startsWith(link.href);
      return <ListItemButton
        component='a'
        href={link.href}
        key={`${link.title}-${title}`}
        onClick={() => setBottomDrawerOpen(false)}
        className={selected ? styles.drawerItemSelected : ''}
      >
        <ListItemText
          primary={link.title}
          className={styles.drawerItemText}
        />
      </ListItemButton>;
    })}
  </div>;
};
