import { makeStyles } from '~/lib/bn-material-ui';
import navTheme from './nav-styles';

export const baseStyles = ({ enableSubmenu }) => {
  return makeStyles((theme) => {
    return {
      root: {
        display: 'flex',
      },
      rootHidden: {
        display: 'none',
      },
      backdrop: {
        zIndex: navTheme.nav.zIndex - 1,
      },
      drawer: {
        zIndex: navTheme.nav.zIndex,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.up('xs')]: {
          flexShrink: 0,
          width: 0,
        },
        [theme.breakpoints.up('sm')]: {
          width: navTheme.nav.menu.width + (enableSubmenu ? navTheme.nav.submenu.width : 0),
        },
      },
      drawerLinksList: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
        overflowX: 'hidden',
      },
      drawerInner: {
        marginTop: navTheme.nav.appBar.minHeight,
        border: 'none',
        backgroundColor: navTheme.nav.menu.backgroundColor,
        color: navTheme.nav.menu.color,
        overflow: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        boxShadow: theme.shadows[3],
        [theme.breakpoints.up('xs')]: {
          flexShrink: 0,
          width: 0,
        },
        [theme.breakpoints.up('sm')]: {
          flexShrink: 0,
          width: navTheme.nav.menu.width,
        },
      },
      drawerItemText: {
        whiteSpace: 'nowrap',
      },
      shield: {
        width: theme.spacing(3),
        position: 'absolute',
        transition: theme.transitions.create('opacity', {
          easing: 'linear',
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      logo: {
        width: '195px',
        transition: theme.transitions.create('opacity', {
          easing: 'linear',
          duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.down('lg')]: {
          opacity: 0,
        },
        opacity: 0,
      },
      logoContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
      },
      subLinkListItem: {
        paddingLeft: '3rem',
      },
      listItem: {
        marginBottom: '5px',
      },
      listItemUnselected: {
        borderWidth: '0',
        '&:hover': {
          backgroundColor: theme.palette.primary.gray[20],
          boxShadow: theme.shadows[3],
          color: navTheme.nav.menu.color,
        },
      },
      listItemSelected: {
        backgroundColor: navTheme.nav.menu.color,
        color: 'var(--white-base)',
        '&:hover': {
          backgroundColor: navTheme.nav.menu.color,
        },
      },
      listItemIcon: {
        color: 'currentColor',
      },
      searchContainer: {
        position: 'relative',
        margin: '10px 0',
        transition: theme.transitions.create('margin', {
          easing: 'linear',
          duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.down('sm')]: {
          margin: '0',
          paddingTop: '20px',
          paddingBottom: '20px',
        },
        [theme.breakpoints.up('sm')]: {
          margin: '0',
        },
      },
      search: {
        position: 'absolute',
        width: `calc(100% - ${theme.spacing(4)})`,
        padding: '10px 6px 10px 6px',
        display: 'flex',
        justifyContent: 'center',
        pointerEvents: 'none',
        transition: theme.transitions.create('opacity', {
          easing: 'linear',
          duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.up('xs')]: {
          opacity: 1,
        },
        [theme.breakpoints.up('sm')]: {
          opacity: 0,
        },
      },
      searchItemIcon: {
        color: 'currentColor',
        transition: theme.transitions.create('opacity', {
          easing: 'linear',
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    };
  })();
};
